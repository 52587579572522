/* eslint-disable max-lines */
import RoomStatus from 'models/enums/RoomStatus.enum';
import MessageType from 'models/enums/MessageType.enum';
import {Message, Reaction, SubmenuButton} from 'models/room';
import appService from 'store/appService';
import userServices from 'store/userService';
import roomServices from 'store/roomService';
import chatServices from 'store/chatService';
import modalService from 'store/modalService';
import threadService from 'store/threadService';
import {FunctionComponent, useEffect, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {v4 as uuidv4} from 'uuid';
import classNames from 'classnames/bind';
import {
	ButtonBlock,
	ButtonChangeRole,
	ButtonCopy,
	ButtonEdit,
	ButtonMessagesVisible,
	ButtonMute,
	ButtonPinned,
	ButtonRemove,
	ButtonReplay,
	ButtonReport,
	ButtonGoToProfile,
	ButtonReplyInThread,
} from './chatMessageSubmenuButtons';
import {ChatMessageSubmenuReactions} from './chatMessageSubmenuReactions';

interface IChatMessageSubmenuProps {
	message: Message;
	reactions?: Reaction[];
	submenuRef?: any;
	isThread?: boolean;
}

const ChatMessageSubmenu: FunctionComponent<IChatMessageSubmenuProps> =
	function ChatMessageSubmenu({message, reactions, submenuRef, isThread}) {
		const {id: messageId, type: messageType, talker} = message;
		const [submenuVisible, setSubmenuVisible] = useState(false);
		const {
			appVoice,
			appSettingsButtonVisible,
			enableOpenProfile,
			appReadOnly,
			appEnableOnboarding,
			appEnableThreads,
		} = useLocalObservable(() => appService);
		const {userData, isUserExternalIdCorrect} = useLocalObservable(() => userServices);
		const {myTalker, roomData, submenuButtons, setSubmenuButtons} = useLocalObservable(() =>
			isThread ? threadService : roomServices
		);

		const {chatBot} = useLocalObservable(() => chatServices);
		const {visibleThreadModal} = useLocalObservable(() => modalService);

		const myTalkerIsBanned = !!myTalker?.bans?.length;
		const commonCheck =
			!appReadOnly &&
			isUserExternalIdCorrect &&
			userData &&
			(!appEnableOnboarding || (appEnableOnboarding && userData.isOnboard)) &&
			talker.user &&
			!myTalkerIsBanned;

		const isMyTalkerModer = !!myTalker?.isModer || !!userData?.isModer;
		const isTalkerModer = talker?.isModer || talker?.user?.isModer;
		const isTalkerSpeaking = !talker.isMuted;
		const isAuthor = userData && talker.user && userData.id === talker.user?.id;
		const isChatBotMessage = talker.user && chatBot && talker.user.name === chatBot.name;
		const roomStatusLive = roomData?.status === RoomStatus.LIVE;
		const picsArray: {url: string; isVisible: boolean; width: number; height: number}[] =
			message.picData ? Array.from(JSON.parse(message.picData)) : [];
		const isPicsArrayVisiblePics =
			message.type === MessageType.PIC &&
			picsArray.filter((el: {url: string; isVisible: boolean}) => el.isVisible).length > 0;
		const isVisibleForMessagePic =
			message.type !== MessageType.PIC ||
			(message.type === MessageType.PIC && (isPicsArrayVisiblePics || message.text !== ''));

		const isVisibleReactions =
			commonCheck &&
			roomStatusLive &&
			(messageType === MessageType.USER ||
				messageType === MessageType.STICKER ||
				messageType === MessageType.BET ||
				messageType === MessageType.PIC ||
				messageType === MessageType.GAMBLE);
		const isCustomImageHighlight =
			messageType === MessageType.ADVERTISEMENT && message.advertisement?.template === 11;

		const chatMessageSubmenuClasses = classNames('chat__submenu', {
			'chat__submenu--visible': submenuVisible,
			'chat__submenu--right': isAuthor,
			'chat__submenu--have-reactions': isVisibleReactions,
		});

		const chatSubmenuItemsClasses = () => {
			const baseClassName = 'chat__submenu-items';
			const filterSubmenuButtonsSmall = submenuButtons.filter((b: any) => b.buttonSizeSmall);
			if (submenuButtons.length > 2 && filterSubmenuButtonsSmall.length) {
				return `${baseClassName} ${baseClassName}--have-small-${filterSubmenuButtonsSmall.length}`;
			}
			return baseClassName;
		};

		const buttonsData: SubmenuButton[] = [
			{
				button: <ButtonReplay message={message} />,
				buttonType: [
					MessageType.USER,
					MessageType.POLLRESULTS,
					MessageType.STICKER,
					MessageType.BET,
					MessageType.GAMBLE,
					MessageType.PIC,
				],
				buttonForTalkersAll: true,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: true,
				buttonAdditionalCondition: roomStatusLive && !!commonCheck && isVisibleForMessagePic,
			},
			{
				button: <ButtonCopy message={message} />,
				buttonType: [MessageType.USER, MessageType.ADVERTISEMENT],
				buttonForTalkersAll: true,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: true,
				buttonAdditionalCondition: !isCustomImageHighlight && !!commonCheck,
			},
			{
				button: <ButtonPinned message={message} />,
				buttonType: [MessageType.USER, MessageType.ADVERTISEMENT, MessageType.POLLRESULTS],
				buttonForTalkersAll: false,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: true,
				buttonAdditionalCondition: roomStatusLive && !!commonCheck && !roomData.isThread,
			},
			{
				button: <ButtonEdit message={message} />,
				buttonType: [MessageType.USER, MessageType.PIC, MessageType.BET, MessageType.GAMBLE],
				buttonForTalkersAll: true,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: false,
				buttonAdditionalCondition:
					!!isAuthor && roomStatusLive && !!commonCheck && isVisibleForMessagePic,
			},
			{
				button: <ButtonReplyInThread message={message} />,
				buttonType: [
					MessageType.USER,
					MessageType.POLLRESULTS,
					MessageType.BET,
					MessageType.GAMBLE,
					MessageType.PIC,
				],
				buttonForTalkersAll: true,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: false,
				buttonAdditionalCondition:
					roomStatusLive &&
					!!commonCheck &&
					!visibleThreadModal &&
					!roomData.isThread &&
					isVisibleForMessagePic &&
					appEnableThreads,
			},
			{
				button: <ButtonGoToProfile message={message} />,
				buttonType: [
					MessageType.USER,
					MessageType.POLLRESULTS,
					MessageType.STICKER,
					MessageType.BET,
					MessageType.GAMBLE,
					MessageType.PIC,
				],
				buttonForTalkersAll: true,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: false,
				buttonAdditionalCondition:
					!isAuthor && enableOpenProfile && !appReadOnly && isVisibleForMessagePic,
			},
			{
				button: <ButtonChangeRole message={message} />,
				buttonType: [MessageType.USER, MessageType.PIC],
				buttonForTalkersAll: false,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: false,
				buttonAdditionalCondition:
					!isAuthor &&
					isMyTalkerModer &&
					!isTalkerModer &&
					appVoice &&
					!isChatBotMessage &&
					!!commonCheck &&
					roomStatusLive &&
					!isThread,
			},
			{
				button: <ButtonMute message={message} />,
				buttonType: [MessageType.USER],
				buttonForTalkersAll: false,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: false,
				buttonAdditionalCondition:
					!isAuthor &&
					isMyTalkerModer &&
					!isTalkerModer &&
					appVoice &&
					isTalkerSpeaking &&
					!!commonCheck &&
					roomStatusLive &&
					!isThread,
			},
			{
				button: <ButtonMessagesVisible message={message} />,
				buttonType: [
					MessageType.USER,
					MessageType.POLLRESULTS,
					MessageType.STICKER,
					MessageType.BET,
					MessageType.GAMBLE,
					MessageType.PIC,
				],
				buttonForTalkersAll: false,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: false,
				buttonAdditionalCondition:
					!isAuthor && roomStatusLive && !!commonCheck && isVisibleForMessagePic,
			},

			{
				button: <ButtonReport message={message} />,
				buttonType: [MessageType.USER, MessageType.STICKER, MessageType.PIC],
				buttonForTalkersAll: true,
				buttonForMyTalkerModer: false,
				buttonSizeSmall: false,
				buttonAdditionalCondition:
					!isAuthor && roomStatusLive && !!commonCheck && isVisibleForMessagePic,
			},
			{
				button: <ButtonRemove message={message} />,
				buttonType: [
					MessageType.USER,
					MessageType.POLLRESULTS,
					MessageType.STICKER,
					MessageType.BET,
					MessageType.GAMBLE,
					MessageType.PIC,
				],
				buttonForTalkersAll: true,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: false,
				buttonAdditionalCondition:
					(!!isAuthor ||
						(isMyTalkerModer && !isTalkerModer && messageType === MessageType.POLLRESULTS)) &&
					roomStatusLive &&
					!!commonCheck,
			},
			{
				button: <ButtonBlock message={message} />,
				buttonType: [MessageType.USER, MessageType.STICKER, MessageType.PIC],
				buttonForTalkersAll: true,
				buttonForMyTalkerModer: true,
				buttonSizeSmall: false,
				buttonAdditionalCondition:
					!isAuthor &&
					!isTalkerModer &&
					!isChatBotMessage &&
					roomStatusLive &&
					(appSettingsButtonVisible || isMyTalkerModer) &&
					!!commonCheck &&
					isVisibleForMessagePic,
			},
		];

		const getSubmenuButtons = () => {
			const filterButtons = buttonsData.filter(
				item =>
					item.button &&
					item.buttonType.includes(messageType) &&
					item.buttonAdditionalCondition &&
					((item.buttonForTalkersAll && !isMyTalkerModer) ||
						item.buttonForMyTalkerModer === isMyTalkerModer)
			);
			if (filterButtons.length) {
				setSubmenuButtons(filterButtons);
			}
		};

		const renderButton = (item: SubmenuButton) => {
			const {button, buttonSizeSmall} = item;
			return (
				<div
					className={`chat__submenu-item ${buttonSizeSmall ? 'chat__submenu-item--small' : ''}`}
					key={uuidv4()}>
					{button}
				</div>
			);
		};

		useEffect(() => {
			if (submenuButtons.length) {
				setSubmenuVisible(true);
			}
		}, [submenuButtons]);

		useEffect(() => {
			getSubmenuButtons();
		}, [message, myTalker]);

		return (
			<div ref={submenuRef} className={chatMessageSubmenuClasses}>
				{isVisibleReactions && (
					<ChatMessageSubmenuReactions
						messageId={messageId}
						reactions={reactions}
						userId={message.talker?.user?.id}
					/>
				)}
				<div className={chatSubmenuItemsClasses()}>{submenuButtons.map(renderButton)}</div>
			</div>
		);
	};

export default observer(ChatMessageSubmenu);

/* eslint-disable max-lines */
import RoomStatus from 'models/enums/RoomStatus.enum';
import {AgoraStatus} from 'models/enums/AgoraStatus.enum';
import TooltipType from 'models/enums/TooltipType.enum';
import UserRole from 'models/enums/UserRole.enum';
import appService from 'store/appService';
import userServices from 'store/userService';
import roomServices from 'store/roomService';
import controlPanelServices from 'store/controlPanelService';
import emotionServices from 'store/emotionService';
import tooltipsServices from 'store/tooltipsService';
import modalService from 'store/modalService';
import alertService from 'store/alertService';

import SocketIoServices from 'services/SocketIoServices';
import useL10n from 'l10n/useL10n';
import useOnboarding from 'hooks/useOnboarding';
import useEmotions from 'hooks/useEmotions';
import useLongPress from 'hooks/useLongPress';
import useAnalytics from 'hooks/useAnalytics';
import useAppData from 'hooks/useAppData';
import useUser from 'hooks/useUser';
import useAgora from 'hooks/useAgora';
import useBanUsers from 'hooks/useBanUsers';

import {FunctionComponent, useEffect, useRef, useState} from 'react';
import {observer, useLocalObservable} from 'mobx-react-lite';
import {CSSTransition} from 'react-transition-group';
import classNames from 'classnames';
import Tooltip from 'components/tooltip/Tooltip';
import TooltipPositoinType from 'models/enums/TooltipPositoinType.enum';
import Button from 'components/hoc/Button';

import {ChatTextarea} from '../chat';
import ControlPanelLeft from './ControlPanelLeft';
import './controlPanel.scss';

const TRANSITION_DURATION_BUTTONS = 500;
const TIMEOUT_TOGGLE_ACTIVE_EMOTION_BUTTON = 350;
const TIMEOUT_EMOTION_TOOLTIP = 10000;

const LONG_PRESS_DEFAULT_OPTIONS = {
	shouldPreventDefault: true,
	delay: 500,
};

const ControlPanel: FunctionComponent = function ControlPanel() {
	const referenceEmotion = useRef<HTMLDivElement>(null);
	const referenceHand = useRef<HTMLDivElement>(null);
	const timeoutEmotionButtonRef: {current: NodeJS.Timeout | null} = useRef(null);
	const timeoutEmotionToolTipRef: {current: NodeJS.Timeout | null} = useRef(null);
	const timeoutWantSpeakToolTipVisibilityRef: {current: NodeJS.Timeout | null} = useRef(null);

	const {
		project,
		appReadOnly,
		appVoice,
		agoraStatus,
		appIcons,
		appEnableOnboarding,
		appShowAnimatedEmotions,
		roomStatuses,
		language,
		projectLanguage,
		isStand,
	} = useLocalObservable(() => appService);
	const {accessToken, userData, isUserExternalIdCorrect} = useLocalObservable(() => userServices);
	const {roomId, updateTalkerMic, myTalker, roomData, speakers} = useLocalObservable(
		() => roomServices
	);
	const {visibleButtons} = useLocalObservable(() => controlPanelServices);
	const {emotions, emotionsVisible, idEmotionActive, toggleEmotionsVisible} = useLocalObservable(
		() => emotionServices
	);
	const {tooltip, toggleTooltip} = useLocalObservable(() => tooltipsServices);
	const {imagePreviewModalVisible} = useLocalObservable(() => modalService);
	const {alert} = useLocalObservable(() => alertService);
	const [isAgoraInited, setIsAgoraInited] = useState(false);
	const [isEmotionButtonActive, setIsEmotionButtonActive] = useState(false);
	const [emotionToolTipVisibility, setEmotionToolTipVisibility] = useState(false);
	const [wantSpeakToolTipVisibility, setWantSpeakToolTipVisibility] = useState(false);

	const {controlPanel, tooltips} = useL10n();
	const {onBoardingStageHandler} = useOnboarding();
	const {sendEmotion} = useEmotions();
	const {sendAnalytics} = useAnalytics();
	const {getAppIcon} = useAppData();
	const {userExtraDataCheckTooltip, userExtraDataPatchTooltip} = useUser();
	const {switchMicrophone} = useAgora();
	const {handleBans} = useBanUsers();

	const {IcoHandPlain, IcoHandPlainAccent, IcoMic, IcoMicAccent} = appIcons;
	let isMuted = myTalker?.isMuted;

	const myTalkerIsBanned = !!myTalker?.bans?.length;
	const myUserIsBanned = !!userData?.bans?.length;

	const checkVisibleMic =
		myTalker?.role === UserRole.SPEAKER || myTalker?.isModer || userData?.isModer;

	const roomStatusEnded =
		roomStatuses.find(item => item.status === RoomStatus.ENDED && item.lang === language) ||
		roomStatuses.find(item => item.status === RoomStatus.ENDED && item.lang === projectLanguage);

	const controlPanelClasses = classNames('control-panel', {
		'control-panel--banned': myTalkerIsBanned || myUserIsBanned,
	});

	const controlPanelBtnEmotionsClasses = classNames(
		'control-panel__btn control-panel__btn--emotions',
		{
			'control-panel__btn--active': isEmotionButtonActive,
		}
	);

	const controlPanelInfoButtonClasses = classNames('control-panel__info-button', {
		'control-panel__info-button--disabled': alert,
	});

	const getSrcByIdEmotionActive = () => {
		const findEmotion = emotions.find(emotion => emotion.id === idEmotionActive);
		return `url('${findEmotion?.pic}')`;
	};

	const clearEmotionButtonTimeout = () => {
		if (timeoutEmotionButtonRef.current) {
			clearTimeout(timeoutEmotionButtonRef.current);
			setIsEmotionButtonActive(false);
		}
	};

	const onMutedHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect && isMuted !== undefined && isAgoraInited) {
			if (userData?.id) {
				updateTalkerMic(userData.id, !isMuted);
			}
			if (userData && userData.id && roomId) {
				SocketIoServices.emitMute(roomId, !isMuted);
			}
			switchMicrophone(!isMuted);
		}
	};

	const onSpeakerRequestHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect && roomId) {
			SocketIoServices.emitToggleHand(roomId);
		}
	};

	const onToogleEmotionsHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect && !myTalkerIsBanned) {
			toggleEmotionsVisible(!emotionsVisible);
			setEmotionToolTipVisibility(false);
			if (tooltip?.shown) {
				toggleTooltip(null);
			}
			if (!emotionsVisible) {
				sendAnalytics('emo_panel_pressed');
			}
		}
	};

	const onSendEmotionHandler = () => {
		if (!appReadOnly && isUserExternalIdCorrect) {
			const findEmotion = emotions.find(emotion => emotion.id === idEmotionActive);
			if (findEmotion) {
				sendEmotion(findEmotion);
				if (emotionsVisible) {
					toggleEmotionsVisible(false);
				}
				if (timeoutEmotionButtonRef.current) {
					clearEmotionButtonTimeout();
				}
				setIsEmotionButtonActive(true);
				timeoutEmotionButtonRef.current = setTimeout(() => {
					setIsEmotionButtonActive(false);
				}, TIMEOUT_TOGGLE_ACTIVE_EMOTION_BUTTON);
			}
		}
	};

	const longPressEvent = useLongPress(
		onToogleEmotionsHandler,
		onSendEmotionHandler,
		LONG_PRESS_DEFAULT_OPTIONS
	);

	const infoClickHandler = () => {
		if (myTalker?.bans?.length) {
			handleBans(myTalker?.bans);
			return;
		}
		if (myTalker?.user?.bans?.length) handleBans(myTalker?.user?.bans);
	};

	useEffect(() => {
		isMuted = myTalker?.isMuted;
	}, [myTalker?.isMuted]);

	useEffect(() => {
		if (myTalkerIsBanned && emotionsVisible) {
			toggleEmotionsVisible(false);
		}
	}, [myTalkerIsBanned]);

	useEffect(() => {
		if (agoraStatus === AgoraStatus.INITED) {
			setIsAgoraInited(true);
		}
	}, [agoraStatus]);

	useEffect(() => {
		if (
			!userData?.isModer &&
			!myTalker?.isModer &&
			speakers.length &&
			(!tooltip ||
				!tooltip.shown ||
				(tooltip && tooltip.shown && tooltip.type === TooltipType.INFORMATIONAL))
		) {
			if (userData && !userExtraDataCheckTooltip(userData, 'wantSpeak')) {
				timeoutWantSpeakToolTipVisibilityRef.current = setTimeout(() => {
					setWantSpeakToolTipVisibility(true);
					if (!tooltip?.shown) {
						toggleTooltip({shown: true, type: TooltipType.TRIGGER});
					}
					userExtraDataPatchTooltip(userData, 'wantSpeak');
				}, 3000);
			}
		}
	}, [userData, myTalker, speakers, tooltip]);

	useEffect(() => {
		if (accessToken)
			if (userData && !userExtraDataCheckTooltip(userData, 'emotion')) {
				timeoutEmotionToolTipRef.current = setTimeout(() => {
					setEmotionToolTipVisibility(true);
					userExtraDataPatchTooltip(userData, 'emotion');
				}, TIMEOUT_EMOTION_TOOLTIP);
			}

		return () => {
			clearEmotionButtonTimeout();

			if (timeoutEmotionToolTipRef.current) {
				clearTimeout(timeoutEmotionToolTipRef.current);
			}

			if (timeoutEmotionButtonRef.current) {
				clearEmotionButtonTimeout();
			}

			if (timeoutWantSpeakToolTipVisibilityRef.current) {
				clearTimeout(timeoutWantSpeakToolTipVisibilityRef.current);
			}
		};
	}, [accessToken, userData]);

	if (
		(roomData && roomData.status === RoomStatus.LIVE) ||
		(roomData &&
			roomData.status === RoomStatus.SOON &&
			!(myTalker && (!myTalker.isModer || userData?.isModer) && myTalker.role === UserRole.GUEST))
	) {
		if (appEnableOnboarding && !userData?.isOnboard && isStand) {
			return (
				<div className='control-panel'>
					<div className='control-panel__onboarding control-panel__onboarding--stand'>
						<Button
							className='control-panel__onboarding-button btn'
							aria-label='control-panel-onboarding-button'
							onClick={onBoardingStageHandler}>
							Join chat
						</Button>
						<div className='control-panel__onboarding-text'>
							You can only read the chat. If you want to join it, please, authorize.
						</div>
					</div>
				</div>
			);
		}
		return (
			<div className={controlPanelClasses}>
				{appReadOnly && !isUserExternalIdCorrect && <div className='control-panel__onboarding' />}

				{!appReadOnly &&
					isUserExternalIdCorrect &&
					appEnableOnboarding &&
					!userData?.isOnboard &&
					!isStand && (
						<Button
							className='control-panel__onboarding'
							aria-label='control-panel-onboarding'
							onClick={onBoardingStageHandler}
						/>
					)}

				{(myTalkerIsBanned || myUserIsBanned) && (
					<button
						type='button'
						className={controlPanelInfoButtonClasses}
						onClick={infoClickHandler}>
						?
					</button>
				)}
				<ControlPanelLeft />

				<div className='control-panel__center'>
					<ChatTextarea visibleButtons={visibleButtons} />
				</div>

				<CSSTransition
					in={visibleButtons || checkVisibleMic}
					timeout={TRANSITION_DURATION_BUTTONS}
					classNames='fade'
					unmountOnExit>
					<div className='control-panel__right'>
						{!myTalker?.bans?.length && !roomData.isThread && (
							<CSSTransition
								in={appVoice}
								timeout={TRANSITION_DURATION_BUTTONS}
								unmountOnExit
								classNames='fade'>
								<div className='control-panel__right-group' ref={referenceHand}>
									{checkVisibleMic ? (
										<Button
											className='control-panel__btn'
											disabled={!isAgoraInited}
											onClick={onMutedHandler}>
											{isMuted ? getAppIcon(IcoMic.pic) : getAppIcon(IcoMicAccent.pic)}
										</Button>
									) : (
										<>
											<Button className='control-panel__btn' onClick={onSpeakerRequestHandler}>
												{myTalker?.hand
													? getAppIcon(IcoHandPlainAccent.pic)
													: getAppIcon(IcoHandPlain.pic)}
											</Button>

											{wantSpeakToolTipVisibility && (
												<Tooltip
													referenceElement={referenceHand.current}
													position={TooltipPositoinType.TOP}
													text={tooltips.wantSpeak}
													delay={3000}
													callbackOnHide={() => {
														setWantSpeakToolTipVisibility(false);
														if (tooltip && tooltip.shown && tooltip.type === TooltipType.TRIGGER) {
															toggleTooltip(null);
														}
													}}
												/>
											)}
										</>
									)}
								</div>
							</CSSTransition>
						)}

						{!imagePreviewModalVisible && appShowAnimatedEmotions && (
							<CSSTransition
								in={visibleButtons}
								timeout={TRANSITION_DURATION_BUTTONS}
								classNames='fade'
								unmountOnExit>
								<div className='control-panel__btn-container' ref={referenceEmotion}>
									<Button className={controlPanelBtnEmotionsClasses} {...longPressEvent}>
										<span
											className='control-panel__btn-ico'
											style={{backgroundImage: getSrcByIdEmotionActive()}}
										/>
									</Button>

									{emotionToolTipVisibility && (
										<Tooltip
											referenceElement={referenceEmotion.current}
											position={TooltipPositoinType.TOP}
											text={tooltips.emotions}
											delay={3000}
											callbackOnHide={() => {
												setEmotionToolTipVisibility(false);
												if (tooltip?.shown) {
													toggleTooltip(null);
												}
											}}
										/>
									)}
								</div>
							</CSSTransition>
						)}
					</div>
				</CSSTransition>
			</div>
		);
	}

	return (
		<div className='control-panel'>
			<div className='control-panel__stream-ended'>
				{roomStatusEnded?.title || controlPanel.streamEnded}
			</div>
		</div>
	);
};

export default observer(ControlPanel);
